.bg-hero{
    background-image: linear-gradient(rgba(0, 0, 0, 0.556), rgba(0, 0, 0, 0.531)) ,url(./img/bghouse.jpg);
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.land{
    background-image: linear-gradient(rgba(0, 0, 0, 0.556), rgba(0, 0, 0, 0.531)) ,url(./img/land.jpg);
    height: 30vh;
    width: 20vw;
    background-position: center;
    border-radius: 10px;
    background-size: cover;
}
.red{
    background-image: linear-gradient(rgba(0, 0, 0, 0.556), rgba(0, 0, 0, 0.531)) ,url(./img/reduced.jpg);
    height: 30vh;
    width: 20vw;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover;
}
.oh{
    background-image: linear-gradient(rgba(0, 0, 0, 0.556), rgba(0, 0, 0, 0.531)) ,url(./img/oh.jpg);
    height: 30vh;
    width: 20vw;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover;
}
.sh{
    background-image: linear-gradient(rgba(0, 0, 0, 0.556), rgba(0, 0, 0, 0.531)) ,url(./img/sh.jpg);
    height: 30vh;
    width: 20vw;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover;
}
.con{
    background-image: linear-gradient(rgba(0, 0, 0, 0.556), rgba(0, 0, 0, 0.531)) ,url(./img/construct.jpg);
    height: 30vh;
    width: 20vw;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover;
}
.nh{
    background-image: linear-gradient(rgba(0, 0, 0, 0.556), rgba(0, 0, 0, 0.531)) ,url(./img/nh.jpg);
    height: 30vh;
    width: 20vw;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover;
}
.land:hover,.red:hover,.oh:hover,.sh:hover,.con:hover,.nh:hover{
    filter: hue-rotate(190deg);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
/* eke;ed */
.toy{
    background-image: linear-gradient(rgba(0, 0, 0, 0.461), rgba(0, 0, 0, 0.528)), url(./img/toyota.jpg);
    height: 30vh;
    width: 20vw;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover;
}
.nis{
    background-image: linear-gradient(rgba(0, 0, 0, 0.461), rgba(0, 0, 0, 0.528)), url(./img/nissan.jpg);
    height: 30vh;
    width: 20vw;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover;
}
.bmw{
    background-image: linear-gradient(rgba(0, 0, 0, 0.461), rgba(0, 0, 0, 0.528)), url(./img/bmw.jpg);
    height: 30vh;
    width: 20vw;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover;
}
.ford{
    background-image: linear-gradient(rgba(0, 0, 0, 0.461), rgba(0, 0, 0, 0.528)), url(./img/ford.jpg);
    height: 30vh;
    width: 20vw;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover;
}
.benz{
    background-image: linear-gradient(rgba(0, 0, 0, 0.461), rgba(0, 0, 0, 0.528)), url(./img/benz.jpg);
    height: 30vh;
    width: 20vw;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover;
}
.lex{
    background-image: linear-gradient(rgba(0, 0, 0, 0.461), rgba(0, 0, 0, 0.528)), url(./img/lexus.jpg);
    height: 30vh;
    width: 20vw;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover;
}
.toy:hover,.nis:hover,.benz:hover,.bmw:hover,.ford:hover,.lex:hover{
    scale: 1.1;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}
@media screen and (max-width:960px) {
    .bg-hero{
        height: 90vh;
    }
    .land, .red, .sh, .oh, .con, .nh{
        width: 60vw;
    }   
    .toy,.nis,.benz,.bmw,.ford,.lex{
        width: 60vw;
    }
}
.land, .red, .sh, .oh, .con, .nh, .bg-hero{
    image-rendering: pixelated;
    background-attachment: scroll;
}   
.toy,.nis,.benz,.bmw,.ford,.lex{
    image-rendering: pixelated;
    background-attachment: scroll;
}